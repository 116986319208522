<template>
    <div class="px-5 py-8 wrapper">
        <img class="w-full max-w-[297px] mx-auto mb-16" :src="logo">
        <div>
            <h1 class="heading-xl text-white font-bold text-center mb-4">Smartast på kvartersfesten - har du vad
                som krävs?</h1>
            <p class="body-md text-white text-center">Känner du dig vass i pallet och är redo att sätta din
                intelligens på prov mot andra ljushuven? Nu har du chansen att skaffa bragging rights genom att plocka
                hem titeln:</p>
            <p class="body-lg text-white font-bold text-center">Smartast på festen</p>
            <svg class="mx-auto mb-8" width="192" height="12" viewBox="0 0 192 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H191L21.0962 9.5L155.071 11" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>

        <div class="bg-lightblue p-6 rounded-2xl -rotate-[4deg] mb-8">
            <p class="body-lg text-white text-center">Testet går ut på att svara på 15 frågor med så många rätt och på
                så kort tid som möjligt. Tar du dig upp på vår highscorelista? Lycka till!</p>
        </div>
        <button @click="startQuiz" class="button">
            Sätt igång
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2633 7.53826H0.399475V5.89987H17.2632L12.9273 1.56397L14.0859 0.405457L20.3995 6.71908L14.0859 13.0327L12.9273 11.8742L17.2633 7.53826Z"
                    fill="#FBF5BE" />
            </svg>
        </button>
    </div>

</template>

<script>
import logo from '@assets/img/logotype.png'

export default {
    name: 'StartScreen',
    methods: {
        startQuiz() {
            this.$store.dispatch('updateTransition', 'slide-right')
            this.$router.push({ name: 'questions' })
        }
    },
    computed: {
        logo() {
            return logo
        }
    }
}
</script>
