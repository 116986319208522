import q1 from '@assets/img/q1.png'
import q3 from '@assets/img/q3.png'
import q4 from '@assets/img/q4.png'
import q6 from '@assets/img/q6.png'
import q8 from '@assets/img/q8.png'
import q11 from '@assets/img/q11.png'
import q13 from '@assets/img/q13.png'

let imagetAlternativeA =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><circle cx="8" cy="8" r="6" stroke="#fff" stroke-width="4"/></svg>'
let imagetAlternativeB =
    '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="2.5" stroke="#fff" stroke-width="5"/></svg>'
let imagetAlternativeC =
    '<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="14" cy="14" r="6" stroke="#fff" stroke-width="4"/><circle cx="14" cy="14" r="13" stroke="#fff" stroke-width="2"/></svg>'
let imagetAlternativeD =
    '<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none"><circle cx="19" cy="19" r="6" stroke="#fff" stroke-width="4"/><circle cx="19" cy="19" r="13" stroke="#fff" stroke-width="2"/><circle cx="19" cy="19" r="18" stroke="#fff" stroke-width="2"/></svg>'

export const questions = [
    {
        id: 0,
        question: 'Hur många<br/>kvadrater hittar du?',
        questionImage: q1,
        options: [
            { text: 'a. 2', isCorrect: false },
            { text: 'b. 4', isCorrect: false },
            { text: 'c. 8', isCorrect: true },
            { text: 'd. 18', isCorrect: false }
        ]
    },
    {
        id: 1,
        question: 'Hur många kulor ligger det i lådan som har nummer 8 när Albert är klar?',
        questionText: 'Vilket nummer kommer härnäst i talserien:<br/>1, 1, 2, 3, 5, 8',
        options: [
            { text: 'a. 1', isCorrect: false },
            { text: 'b. 5', isCorrect: false },
            { text: 'c. 13', isCorrect: true },
            { text: 'd. 22', isCorrect: false }
        ]
    },
    {
        id: 2,
        question: 'Vilket tal ska ersätta frågetecknet?',
        questionImage: q8,
        options: [
            { text: 'a. 4', isCorrect: false },
            { text: 'b. 6', isCorrect: false },
            { text: 'c. 18', isCorrect: true },
            { text: 'd. 36', isCorrect: false }
        ]
    },
    {
        id: 3,
        question: 'Hur många dygn?',
        questionText:
            'På en äng växer det mycket snabbväxande blommor. Ytan som täcks av blommor fördubblas varje dygn. Efter 30 dygn var ängen helt täckt  av blommor. Efter hur många dygn var ängen täckt till hälften?',
        options: [
            { text: 'a. 1', isCorrect: false },
            { text: 'b. 10', isCorrect: false },
            { text: 'c. 20', isCorrect: false },
            { text: 'd. 29', isCorrect: true }
        ]
    },
    {
        id: 4,
        question: 'Vad kostar bollen?',
        questionText:
            'En boll och en basketkorg kostar 150 kr. Basketkorgen kostar 100 kr mer än bollen. Vad kostar bollen?',
        options: [
            { text: 'a. 15 kr', isCorrect: false },
            { text: 'b. 50 kr', isCorrect: false },
            { text: 'c. 100 kr', isCorrect: false },
            { text: 'd. 25 kr', isCorrect: true }
        ]
    },
    {
        id: 5,
        question: 'Välj ett logiskt svar',
        questionImage: q3,
        isImageAlternative: true,
        options: [
            { text: 'a. 15', imageAlternative: imagetAlternativeA, isCorrect: true },
            { text: 'b. 9', imageAlternative: imagetAlternativeB, isCorrect: false },
            { text: 'c. 7', imageAlternative: imagetAlternativeC, isCorrect: false },
            { text: 'd. 10', imageAlternative: imagetAlternativeD, isCorrect: false }
        ]
    },
    {
        id: 6,
        question: 'Hur många pärlor?',
        questionText:
            'Du har fem burkar med pärlor i. I genomsnitt har du 30 pärlor i varje burk. Du får en till burk med pärlor och nu är det i genomsnitt 32 pärlor i varje burk. Hur många pärlor är det i den nya burken?',
        options: [
            { text: 'a. 24', isCorrect: false },
            { text: 'b. 35', isCorrect: false },
            { text: 'c. 40', isCorrect: false },
            { text: 'd. 42', isCorrect: true }
        ]
    },
    {
        id: 7,
        question: 'Vilken medlemsbyrå hängde med FAR i vårt tält?',
        questionText: '',
        options: [
            { text: 'a. Elera', isCorrect: false },
            { text: 'b. Ekonomibolaget', isCorrect: true },
            { text: 'c. Revideco', isCorrect: false },
            { text: 'd. Aspia', isCorrect: false }
        ]
    },
    {
        id: 8,
        question: 'Vad heter rapporten?',
        questionText:
            'Vad heter rapporten som FAR och Järvaveckan Research släppte i samband med Järvaveckan?<br> a. Branschens möjligheter att hjälpa företag i utsatta områden.<br>b. Utmaningar och möjligheter för företag i utsatta områden.<br>c. Företagandets förutsättningar i utsatta områden<br>d. Företagandets möjligheter',
        options: [
            { text: 'A', isCorrect: false },
            { text: 'B', isCorrect: false },
            { text: 'C', isCorrect: true },
            { text: 'D', isCorrect: false }
        ]
    },
    {
        id: 9,
        question: 'Vad heter FAR:s konto på Tiktok?',
        questionText: '',
        options: [
            { text: 'a. Ekonomisverige', isCorrect: false },
            { text: 'b. Ekonomibranchen', isCorrect: false },
            { text: 'c. Ekonomivägen', isCorrect: true },
            { text: 'd. Ekonomiföretaget', isCorrect: false }
        ]
    }
]
