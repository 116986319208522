<template>
    <div class="tv-wrapper">

        <div v-if="leaderboard.length > 0" class="text-center mb-[5%]">
            <p class="current-leader-text">Just nu är:</p>
            <p class="leader-text text-white relative">
                <span class="name-highlight">
                    <svg width="84" height="97" viewBox="0 0 84 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52.9199 62.7405L7.84772 39.8778M80.2222 49.3802L58.6588 3.67212M54.5 93.5001H3.5"
                            stroke="#A1ECDD" stroke-width="7" stroke-linecap="round" />
                    </svg>
                    {{ leaderboard[0].name }}
                    <svg width="85" height="97" viewBox="0 0 85 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.08 62.7404L77.1523 39.8776M4.32788 49.38L25.8913 3.672M30.5 93.5H81.5"
                            stroke="#A1ECDD" stroke-width="7" stroke-linecap="round" />
                    </svg>
                </span>
                smartast på festen
            </p>
        </div>


        <div class="bg-lightblue p-[5%] rounded-[70px] mb-[5%]">
            <h2 style="font-size:calc(42px + 0.5vw);" class="text-white text-center font-medium">Topp 10 smartast på
                festen
                idag</h2>
            <svg class="w-full mb-[5%]" viewBox="0 0 823 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H822L87.8365 22.25L666.747 26" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div class="flex flex-row" v-if="leaderboard">
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(0, 5)" style="font-size:calc(32px + 0.5vw);"
                        class="text-white font-medium mb-[2%]">{{ index + 1 }}. {{
                            participant.name }}
                    </li>
                </ol>
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(5, 10)" style="font-size:calc(32px + 0.5vw);"
                        class="text-white font-medium mb-[2%]">
                        {{ index + 6 }}. {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>
    </div>



</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
    name: 'ResultsScreen',
    setup() {

        const store = useStore()
        const leaderboard = computed(() => store.state.leaderboard)
        const acfData = ref([])
        const videoRef = ref(null)

        function getACFData() {
            acfData.value = []

            fetch('/wp-json/custom/v1/acf-options/')
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data) {
                        acfData.value = data
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        function getLeaderBoard() {
            var data = {
                action: 'GetLeaderBoard',
            }

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        store.commit('leaderboard', data.leaderboard)
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        const onVideoEnded = () => {
            getACFData()
        };

        watch(videoRef, (newData) => {
            if (newData) {
                console.log(videoRef.value)
                videoRef.value.addEventListener('ended', onVideoEnded, false)
            }
        })

        onMounted(() => {

            getACFData()
            watch(acfData, (newData) => {
                if (newData.video && videoRef.value) {
                    videoRef.value.play().catch(error => {
                        console.error('Autoplay failed:', error)
                    })
                }
            })
            getLeaderBoard()

            setInterval(() => {
                getLeaderBoard()
            }, 5000)

        })

        return {
            leaderboard,
            acfData,
            videoRef
        }
    }
}
</script>

<style scoped>
.tv-wrapper {
    @apply w-[90%] mx-auto;
}

.tv-heading {
    font-size: calc(32px + 0.5vw);
    line-height: 1.1;
    font-weight: 500;
}

.current-leader-text {
    font-size: calc(46px + 0.5vw);
    line-height: 1.1;
    font-weight: 500;
    @apply text-white mb-[2%];
}

.leader-text {
    font-size: calc(66px + 1vw);
    line-height: 1.1;
    font-weight: 500;
}

.name-highlight {
    @apply flex flex-row items-center justify-center gap-[2%] font-bold;
}

.name-highlight svg {
    position: relative;
    margin-top: -5%;
}


.tv-text {
    font-size: calc(16px + 0.5vw);
    line-height: 1.6;
}
</style>